
<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard>
          <form id="CreateAd" @submit="CreateAd">
            <CCardHeader>
              <CCol col="6" style="font-size: 25px">إضافة إعلان</CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <div class="form-group">
                    <label for="image">
                      الصورة<span class="star">*</span></label
                    >
                    <input
                      type="file"
                      id="image"
                      v-on:change="onFileChange"
                      class="form-control"
                      required
                    />
                    <div v-if(errors.image) id="validateImage" class="invalid-feedback">
                      {{errors.image}}
                    </div>
                    <small class="form-text text-muted">حجم الصورة يجب ان لايتجاوز 5 ميقا</small>
                  </div>
                  <div class="form-group">
                    <label for="categories">
                      الفئات
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      class="required"
                      v-model="selected_category"
                      :value="selected_category"
                      id="categories"
                      :options="Categories"
                      :searchable="true"
                      selectedLabel=" العنصر المحدد"
                      deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                      selectLabel="اضغط لإختيار هذا العنصر"
                      :close-on-select="true"
                      placeholder="اختر ..."
                      label="name"
                      track-by="name"
                    >
                      <span slot="noOptions">اللائحة فارغة</span>
                      <span slot="noResult">لا توجد نتيجة</span>
                    </multiselect>
                    <div v-if(errors.category_id) id="validateCategoryId" class="invalid-feedback">
                      {{errors.category_id}}
                    </div>
                  </div>
                  <CInput
                    type="date"
                    label="تاريخ الإنتهاء"
                    placeholder="الاسم باللغة الانجليزية"
                    id="expire_date"
                    v-model="expire_date"
                    class="required"
                    required
                  />
                  <div v-if(errors.expire_date) id="validateExpireDate" class="invalid-feedback">
                    {{errors.expire_date}}
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                size="sm"
                color="primary"
                ><CIcon name="cil-check-circle" /> حفظ</CButton
              >
              &emsp;
              <CButton type="reset" size="sm" color="danger"
                ><CIcon name="cil-ban" /> مسح
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "CreateAd",
  data: function () {
    return {
      Ad: {},
      image: "",
      expire_date: "",
      Categories: [],
      errors: [],
      selected_category: ''
    };
  },
  created() {
    let categories = [];
    this.$http.get(`${process.env.VUE_APP_URL}categories`).then((response) => {
      $.each(response.data.data, function (key, value) {
        categories.push({ id: value.id, name: value.name });
      });
      this.Categories = categories;
    });
  },
  methods: {
    onFileChange(e) {
      this.Ad.image = e.target.files[0];
      this.image = e.target.files[0];
      console.log(this.image);
    },
    CreateAd: function (e) {
      e.preventDefault();

      this.errors = [];
      if (!this.selected_category) this.errors.push(`الفئة الفرعية مطلوبة`);

      let currentObj = this;
      let formData = new FormData();
      if (this.image) {
        if (this.image.size > 5000000) {
          e.preventDefault();
          this.errors.push('image')
          this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
          swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
          return 0;
        } else {
          formData.append("image", this.image);
        }
      }
      formData.append("expire_date", this.expire_date);
      if (this.selected_category.id) {
        formData.append("category_id", this.selected_category.id);
      }
      this.$http
        .post(`${process.env.VUE_APP_URL}ads`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          currentObj.output = response.data;
          console.log(response.data.error);
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
            currentObj.$router.push({ path: "/ads" });
          } else {
            currentObj.errors = response.data.data;
          }
        })
        .catch(function (error) {
          currentObj.output = error;
        });
    },
  },
};
</script>

<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style> 